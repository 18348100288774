import { FieldValues, useForm, UseFormProps, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { errorMap } from '@controls/Form/errorMap'
import * as z from 'zod'

export type UseFormZodOptions<T extends FieldValues> = Readonly<Omit<UseFormProps<T>, 'resolver'>> &
  Readonly<{
    schema: z.ZodType<T>
    errorMap?: z.ZodErrorMap
  }>

export const useZodForm = <T extends FieldValues>(opts: UseFormZodOptions<T>): UseFormReturn<T> => {
  const { t } = useTranslation()

  return useForm<T>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    ...opts,
    resolver: zodResolver(opts.schema, { errorMap: opts.errorMap ?? errorMap(t) })
  })
}
